import React from 'react'
import Layout from '@/components/Layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <div className='min-h-screen flex flex-col justify-center items-center'>
      <div className='container pb-3 '>
        <div className='text-center relative '>
          <h1 className=''>404: Page Not Found</h1>
          <div className=' mt-6 text-lg'>
            We can’t seem to find the page you’re looking for. Try going back to
            the previous page.
          </div>
          <Link to='/' className='btn btn--red btn-s mt-5 mx-auto'>
            Back
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
